






















import {
  Component,
  Vue, Watch
} from 'vue-property-decorator';
import {
  Core
} from '@/store/core'
import {
  Auth
} from '@/store/auth'
import {
  User
} from '@/store/user'
import {
  Web
} from '@/store/web'
import _ from 'lodash'
import xx from '@/tmp/iit.json'
@Component({
  components: {

  },
})
export default class Profile extends Vue {

  name:string = ''
  years:any = null

  response:boolean = false;

  private async test(){
    this.years = await Core.getHttp(`/api/iit/v1/year`);
    this.response = true;

  }

  async created() {
    this.years = await Core.getHttp(`/api/iit/v1/year`);
    this.response = true;
  }

}
